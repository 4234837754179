import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Scroll, Event, NavigationStart } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HistoryService implements OnDestroy {
  private history: string[] = [];
  private destroy$ = new Subject<void>();
  private prevScrollPosition: [number, number] = [0, 0];

  constructor(private router: Router) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initHistory(): void {
    this.history = [];

    this.router.events
      .pipe(
        filter((event): event is NavigationStart => event instanceof NavigationStart),
        takeUntil(this.destroy$),
      )
      .subscribe(event => {
        this.history = [...this.history, event.url];
      });

    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(event => {
        this.history[this.history.length - 1] = event.urlAfterRedirects;
      });

    this.router.events
      .pipe(
        filter((e: Event): e is Scroll => e instanceof Scroll),
        takeUntil(this.destroy$),
      )
      .subscribe(e => {
        if (e.position) {
          // backward navigation
          this.prevScrollPosition = e.position;
          // viewportScroller could be used to move the scroll, but the view hasn't loaded and rendered the data yet
        }
      });
  }

  getPreviousUrl(): string | null {
    return this.history[this.history.length - 2] || null;
  }
  getPrevScrollPosition(): [number, number] {
    return this.prevScrollPosition;
  }
}
